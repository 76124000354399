import React from 'react'
import {keyframes} from 'styled-components'
import styled from 'styled-components'


const PieContainer = styled.div`
width: 33%;
justify-content: space-around ;
`
const progress = keyframes`
0% {
  stroke-dasharray: 0 100;
}
`
const PieChartCircle = styled.svg`
display: block;
margin: 10px auto;
max-width: 80%;
max-height: 250px;
`
const CircleBgPath = styled.path`
fill: none;
stroke-width: 1;
stroke: red;
`
const CirclePath = styled.path`
fill: none;
stroke-width: 1;
stroke: #A8D38D;
stroke-linecap: round;
animation: ${progress} 1s ease-out
`
const PercentageText = styled.text`
  fill: #fff;
  font-family: sans-serif;
  font-size: 0.35em;
  text-anchor: middle;
  font-weight: 100;
}
`

const StatPieGraph = ({winPercentage, wins, losses, label}) => {
  return (
    <PieContainer>
    <PieChartCircle viewBox="0 0 36 36" >
    <CircleBgPath 
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <CirclePath
      strokeDasharray={`${winPercentage}, 100`}
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <PercentageText x="18" y="20.35" class="percentage">{wins}-{losses}</PercentageText>
  </PieChartCircle>
  <p>{label}</p>
  </PieContainer>
  )
}

export default StatPieGraph