import firebase from '../../config/firebase'

export const SET_ACTIVE_TEAMS = "SET_ACTIVE_TEAMS";

export const fetchActiveTeams = () => {
  return async dispatch => {
    try {
      let activeTeams = []
      let activeTeamRef = firebase.firestore().collection('seasons').doc('2019').collection('activeTeams')
      const teamsSnapshot = await activeTeamRef.get().then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        snapshot.forEach(doc => {
          activeTeams.push(doc.data())
        });
        return activeTeams

      })
      dispatch({ type: SET_ACTIVE_TEAMS, payload: teamsSnapshot })

    } catch (err) {
      console.log(err)
    }

  }
}
