import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux'
import scoreReducer from './store/reducers/scoreReducer'
import rewardReducer from './store/reducers/rewardReducer'
import teamReducer from './store/reducers/teamReducer'

import * as serviceWorker from './serviceWorker';

const rootReducer = combineReducers({
  scores: scoreReducer,
  rewards: rewardReducer,
  teams: teamReducer
})

const store = createStore(rootReducer, applyMiddleware(ReduxThunk))


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
