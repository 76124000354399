import { SET_ACTIVE_TEAMS } from '../actions/teamActions'

let initalState = {
  activeTeams: []
}


const teamReducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TEAMS:
      return {
        ...state,
        activeTeams: action.payload
      }
    default:
      return state
  }
}

export default teamReducer;