import { SET_REWARDS } from '../actions/rewardActions'

let initalState = {
  rewards: []
}

const rewardReducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_REWARDS:
      return {
        ...state,
        rewards: action.payload
      }
    default:
      return state
  }
}

export default rewardReducer;