import firebase from '../../config/firebase'

export const SET_REWARDS = "SET_REWARDS";

export const fetchRewards = () => {
  return async dispatch => {
    let rewards = []
    let rewardRef = firebase.firestore().collection('seasons').doc('2019').collection('rewards')
    const documentSnapshot = await
      rewardRef.get().then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        snapshot.forEach(doc => {
          rewards.push(doc.data())
        });
        return rewards
      })
    dispatch({ type: SET_REWARDS, payload: documentSnapshot })
  }
}


