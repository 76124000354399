import { SET_SCORES } from '../actions/scoreActions'

let initalState = {
  players: []
}


const scoreReducer = (state = initalState, action) => {
  switch (action.type) {
    case SET_SCORES:
      return {
        ...state,
        players: action.payload
      }
    default:
      return state
  }
}

export default scoreReducer