import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD6Qs9zZCbLQU2TF_ykU6_ZfF3r59oHs0M",
  authDomain: "tasketball-e9dc5.firebaseapp.com",
  databaseURL: "https://tasketball-e9dc5.firebaseio.com",
  projectId: "tasketball-e9dc5",
  storageBucket: "tasketball-e9dc5.appspot.com",
  messagingSenderId: "700871009070"
}

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;  