import React from 'react';
import logo from './images/Header.svg';
import './App.css';
import ScoresList from './components/ScoresList'
import styled from 'styled-components'


const LogoHeader = styled.img`
margin-top:150px;
width:35%;
@media (max-width: 600px) {
  width: 80%
}
`
const SubHeader = styled.p`
letter-spacing: 2px;
color:#E00000;
font-weight: bold;
`


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LogoHeader src={logo} alt="logo" />
        <SubHeader>LEADERBOARD 2019/20 & STATS</SubHeader>
        <ScoresList />
      </header>
    </div>
  );
}

export default App;
