import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as scoreActions from '../store/actions/scoreActions';
import * as teamActions from '../store/actions/teamActions';
import ScoreslistItem from '../components/ScoresListItem';
import styled from 'styled-components'


const ScoreListContainer = styled.div`
width: 40%;
margin-bottom: 50px;
@media (max-width: 600px) {
  width: 90%
}
`
const Scoreslist = () => {
  const players = useSelector(state => state.scores.players);
  const activeTeams = useSelector(state => state.teams.activeTeams);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(scoreActions.fetchPlayersScores())
  }, [dispatch]);

  useEffect(() => {
    dispatch(teamActions.fetchActiveTeams())
  }, [dispatch]);

  return (
    <ScoreListContainer>
      {players.length >= 1 && activeTeams.length >= 1 ?
        players.map((player) => {
          return (
            <ScoreslistItem
              activeTeams={activeTeams}
              player={player.playerName}
              score={player.score.toFixed(2)}
              rank={players.indexOf(player)}
              wins={player.wins}
              losses={player.losses}
              westTeam={player.teams[0]}
              eastTeam={player.teams[1]}
            />
          )
        })
        : "loading"}
    </ScoreListContainer>
  )
}
export default Scoreslist
