import firebase from '../../config/firebase'

export const SET_SCORES = "SET_SCORES";


export const fetchPlayersScores = () => {
  return async dispatch => {
    let scores = []
    let playerRef = firebase.firestore().collection('seasons').doc('2019').collection('players')
    let orderedPlayerQuery = playerRef.orderBy('score', 'desc')
    const documentSnapshot = await
      orderedPlayerQuery.get().then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        snapshot.forEach(doc => {
          scores.push(doc.data())
        });
        return scores
      })
    dispatch({ type: SET_SCORES, payload: documentSnapshot })
  }
}

