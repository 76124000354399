import React, { useState } from 'react'
import StatPieGraph from './StatPieGraph'
import styled from 'styled-components'



const ScoreItemCard = styled.div`
background-color: #313131;
width:100%;
padding:5px;
margin-bottom:15px;
border-radius:15px;
-webkit-box-shadow: 10px 10px 39px -15px rgba(0,0,0,0.55);
-moz-box-shadow: 10px 10px 39px -15px rgba(0,0,0,0.55);
box-shadow: 10px 10px 39px -15px rgba(0,0,0,0.55);
cursor:pointer;
justify-content:space-between
`
const ScoreDetailContainer = styled.div`
border-top:solid 1px #4A4A4A;
padding:15px;
display:flex;
`


const ScoresListItem = ({ player, score, rank, wins, losses, westTeam, eastTeam, activeTeams }) => {
  const [open, setOpen] = useState(false);

  const calculateTeamStats = (teamId) => {
    let teamStats = {}
    console.log(activeTeams)

    const playerTeam = activeTeams.filter(team => team.teamId === teamId)
    console.log(playerTeam)
    teamStats = {
      wins: Math.ceil(playerTeam[0].rewardsRecieved / playerTeam[0].owners.length),
      losses: Math.ceil(playerTeam[0].rewardsIssued / playerTeam[0].owners.length),
    }
    teamStats.gamesPlayed = teamStats.wins + teamStats.losses
    teamStats.winPercentage = teamStats.wins / teamStats.gamesPlayed * 100

    return teamStats
  }

  const calculateRank = (rankKey) => {
    let rank = parseInt(rankKey) + 1
    return rank
  }

  const teamScores = {
    eastTeam: calculateTeamStats(eastTeam.teamId),
    westTeam: calculateTeamStats(westTeam.teamId)
  }


  return (
    <ScoreItemCard onClick={() => setOpen(!open)}>
      <h4> {calculateRank(rank)} {player} {score}%</h4>
      {
        open ?
          <ScoreDetailContainer>
            <StatPieGraph
              winPercentage={teamScores.eastTeam.winPercentage}
              wins={teamScores.eastTeam.wins}
              losses={teamScores.eastTeam.losses}
              label={eastTeam.nickname}
            />
            <StatPieGraph
              winPercentage={teamScores.westTeam.winPercentage}
              wins={teamScores.westTeam.wins}
              losses={teamScores.westTeam.losses}
              label={westTeam.nickname}
            />
            <StatPieGraph
              winPercentage={score}
              wins={wins}
              losses={losses}
              label="TOTAL"
            />

          </ScoreDetailContainer>
          : null
      }
    </ScoreItemCard>
  )
}

export default ScoresListItem
